import React, { useContext } from 'react'
import { useInView } from 'react-intersection-observer'
import cs from 'classnames'
import GlobalContext from '../components/GlobalContext'

const NotFoundPage = () => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  })
  const { isLoaded } = useContext(GlobalContext)

  return (
    <div
      ref={inViewRef}
      className={cs(
        'header-padding-offset text-center flex items-center justify-center min-h-screen',
        {
          'in-view': inView && isLoaded,
        }
      )}
    >
      <div className="container">
        <h1 className="text-5xl md:text-6xl primary-appearance">
          Ошибка 
          {' '}
          <span className="text-primary-500">404</span>
        </h1>
        <div
          className="text-xl text-gray-700 font-text primary-appearance"
          style={{ transitionDelay: '150ms' }}
        >
          Такой страницы не существует :(
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
